import React, { createContext,useState, useReducer, useEffect } from 'react';
import { PascalReducer } from './reducer';

export const PascalContext = createContext();

const PascalContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [pascal, dispatch] = useReducer(PascalReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('pascal');
    const localPascal = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localPascal) && localPascal.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localPascal
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('pascal', JSON.stringify(pascal));
    }
  }, [pascal]);
  return (
    <PascalContext.Provider value={{ pascal, dispatch }}>
      {children}
    </PascalContext.Provider>
  );
}
 
export default PascalContextProvider;