import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import ShellCard from "@/components/ShellCard"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import useTranslation from "@/hooks/useTranslation"
import PascalContextProvider, { PascalContext } from "@/contexts/Pascal"
import createNodeId from "@/utils/createNodeId"

const PascalMarketPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allPascalYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          shellGoods {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            palette
            customize
          }
          mermaidGoods {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
            palette
            customize
          }
        }
      }
    }
  `)
  const t = useTranslation()
  const { shells, shellGoods, mermaidGoods, ...PascalInfo } = queryData.allPascalYaml.nodes[0]
  
  const totalGoods = [...mermaidGoods,...shellGoods]
  const goodsHasMarked = totalGoods.map((good) => ({
    ...good,
    isCollected: false,
    id: createNodeId(good.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image className="img" src={PascalInfo.avatar.childImageSharp.fluid} />
          <span>{t(PascalInfo.name)}</span>
        </div>
        <MultiLineTabs value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="mermaidSeries" />
          <TextTab label="shellSeries" />
        </MultiLineTabs>
        <MultiLineTabs value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          <TextTab label="notCollected" />
          <TextTab label="collected" />
        </MultiLineTabs>
        <PascalContextProvider>
          <ShellCards shells={goodsHasMarked} tabs={{ value, value2 }} />
        </PascalContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default PascalMarketPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img{
      width: 150px;
      border-radius: 50%;
    }
    span{
      margin: 14px 0 18px;
    }
  }
`

const ShellCards = ({ shells,tabs }) => {
  const { pascal } = useContext(PascalContext)

  const currentShells = useMemo(() => {
    const { value,value2 } = tabs

    const shellsHasMarked = shells.map((shell) => {
      const shellHasCollected = pascal.filter((id) => id === shell.id)
      return {
        ...shell,
        isCollected: shellHasCollected.length ? true : false,
      }
    })

    let shellsArr = [...shellsHasMarked]

    if (value === 1) {
      shellsArr = shellsArr.filter((shell) => shell.type===1)
    } else if (value === 2) {
      shellsArr = shellsArr.filter((shell) => shell.type===2)
    }

    if (value2 === 1) {
      shellsArr = shellsArr.filter((shell) => !shell.isCollected)
    } else if (value2 === 2) {
      shellsArr = shellsArr.filter((shell) => shell.isCollected)
    }

    return shellsArr
  }, [pascal, shells, tabs])

  return (
    <StyledShellCards>
      {currentShells.length ? (
        <div className="shell_cards">
          {currentShells.map((shell) => (
            <ShellCard shell={shell} key={shell.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledShellCards>
  )
}

const StyledShellCards = styled.div`
  .shell_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`